import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { TitleHeader } from "../components/title-header/titleHeader";
import styled from "@emotion/styled";
import readingTime from 'reading-time';
import { Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import { TiltedAccentTitle } from "../components/tilted-title-accent/tilted-title-accent";
import { CrowdCallContainer } from "../components/global/global";
import { ContactFormWrapper } from "../components/contact/contactFormWrapper";
import { Footer } from "../components/footer/footer";

const BlogPostInfo = styled.span`
  font-size: 12px;
  color: rgb(200, 200, 200) !important;
`

const ContentContainer = styled.div`
  max-width: 850px;
  margin: 0 auto;
  padding: 0 50px;
  h1, h2, h3, h4, h5, h6, p {
    margin: 2rem 0;
  }
`

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark

  useEffect(() => {
    // Allow in-line JS scripts to be run
    let scripts = document.querySelectorAll(
      '[data-inline-script="data-inline-script"]'
    )
    scripts.forEach(function forEachScript(element) {
      const script = element.innerHTML
      // eslint-disable-next-line no-use-before-define
      window.eval(script)
    })
  }, [])

  return (
    <CrowdCallContainer>
      <TitleHeader title={frontmatter.title} subtitle={frontmatter.description}>
        <div css={{ paddingTop: "20px" }}>
          <BlogPostInfo>~{Math.ceil(readingTime(html).minutes) + 1} minute read</BlogPostInfo> <br />
          {frontmatter.date.indexOf("2000") === -1 && <BlogPostInfo>Posted {frontmatter.date}</BlogPostInfo>}
        </div>
      </TitleHeader>
      <ContentContainer>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </ContentContainer>
      {frontmatter.ad &&
        <>
          <TiltedAccentTitle
            title="CrowdCall is the best tool for political phonebanking"
            subtitle="CrowdCall is a mission driven organization that builds tools to empower causes and campaigns. Our unique technology integrates directly with the tools you already use, like OpenVPB or Votebuilder.">
            <Link to="/"><Button variant="light">Learn More</Button></Link>
          </TiltedAccentTitle>

          <ContactFormWrapper />

          <Footer />
        </>}
    </CrowdCallContainer>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        ad
      }
    }
  }
`
